import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './components/Main.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
