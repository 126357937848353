import React, { Component, Fragment, useState } from 'react'
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import queryString from 'query-string';

const Styles = styled.div`
  #form-container {
    border: solid white 1px;
    border-radius: 5px;
    margin: 4vh 4vw 4vh 4vw;
    padding: 2vh 2vw 2vh 2vw;
  }
  
  h1 {
    text-align: center;
    margin: 4vh 4vw 1vh 4vw;
  }
  
  label {
    margin-left: 1vw;
  }
  
  .mb-3 {
    margin-left: 4vw;
  }
  
  #genres {
    max-height: 30vh;
    overflow: scroll;
  }
  
  #genres, #artists, #tracks, #name {
    margin: 3vh 1vw 3vh 1vw;
  }
  
  .rbt, #name {
    margin: 1vw;
  }
  
  #submit {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  #playlist {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #login-redirect {
    text-align: center;
    display: block;
  }
  
  #note {
    font-style: italic;
    font-size: 0.78rem;
    color: grey;
  }
  
  #name {
    padding-left: 1vw;
    padding-right: 1vw;
  }
  
  .hidden {
    display: none;
  }
`;

// const API_HOST = "http://localhost:8888/";
const API_HOST = "https://uncb1kkr6j.execute-api.us-east-1.amazonaws.com/dev/";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: ""
    }
  }
  componentDidMount() {
    let values = queryString.parse(this.props.location.search);
    this.setState({ token: values.access_token });
  }
  
  render() {
    let reference = this;
    const ArtistTypeahead = () => {
      const [isLoading, setIsLoading] = useState(false);
      const [options, setOptions] = useState([]);
      const [multiSelections, setMultiSelections] = useState([]);
      
      const handleSearch = (query) => {
        setIsLoading(true);
        debugger;
        let url = `${API_HOST}artists?access_token=${reference.state.token}&q=${query}`;
        fetch(url)
          .then((resp) => resp.json())
          .then((resp) => {
            setOptions(Object.keys(resp));
            debugger;
            setIsLoading(false);
          });
      };

      return (
        <AsyncTypeahead
          id="async-example"
          isLoading={isLoading}
          labelKey="login"
          multiple
          minLength={3}
          onSearch={handleSearch}
          onChange={setMultiSelections}
          selected={multiSelections}
          options={options}
          placeholder="Search for a Spotify artist..."
          renderMenuItemChildren={(option, props) => (
            <Fragment>
              <span>{option}</span>
            </Fragment>
          )}
        />
      );
    };
    
    const TrackTypeahead = () => {
      const [isLoading, setIsLoading] = useState(false);
      const [options, setOptions] = useState([]);
      const [multiSelections, setMultiSelections] = useState([]);

      const handleSearch = (query) => {
        setIsLoading(true);
        debugger;
        let url = `${API_HOST}tracks?access_token=${reference.state.token}&q=${query}`;
        fetch(url)
          .then((resp) => resp.json())
          .then((resp) => {
            setOptions(Object.keys(resp));
            debugger;
            setIsLoading(false);
          });
      };

      return (
        <AsyncTypeahead
          id="async-example"
          isLoading={isLoading}
          labelKey="login"
          minLength={3}
          multiple
          onSearch={handleSearch}
          onChange={setMultiSelections}
          selected={multiSelections}
          options={options}
          placeholder="Search for a Spotify song..."
          renderMenuItemChildren={(option, props) => (
            <Fragment>
              <span>{option}</span>
            </Fragment>
          )}
        />
      );
    };
    
    const redirectUrl = API_HOST + "login?show_dialog=true";
    return(
      <Styles>
        <h1>Make Me A Playlist</h1>
        <span id="login-redirect">Login again <a href={redirectUrl}>here</a></span>
        <div id="form-container">
          <span id="note">NOTE: Up to 5 filters are allowed in total</span>
          <Alert id="limit-alert" variant="danger" className="hidden">
            <strong>
              You can only choose up to 5 filters! Unselect a few and try again
            </strong>
          </Alert>
          <h3>What genres do you like?</h3>
          <div id="genres"></div>
          <h3>Artists?</h3>
          <div id="artists">
            <ArtistTypeahead />
          </div>
          <h3>Songs?</h3>
          <div id="tracks">
            <TrackTypeahead />
          </div>
          <h3>Name?</h3>
          <Form id="name">
            <Form.Control placeholder="Name the playlist..." />
          </Form>
          <Button id="submit">Submit</Button>
          <div id="playlist"></div>
        </div>
      </Styles>
    )
  }
}

export default Main;
